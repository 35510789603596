import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { ProceedSaveLater } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { MFTextField } from '../../lib/formik';
import {
  Applicant,
  ApplicationProps,
  JointholderRelationMaster,
  DistributorListType,
} from '../../redux-store/types/api-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { ClassPlanProps, FundProps } from '../../redux-store/types/funds';
import { investmentPaymentDetailsSchema } from '../../utils/schema';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APPLICATION_TYPE,
  USER_ROLES,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  checkIfApplicationIsNonIndividual,
  currencyConversion,
  drawDownDateStarting,
  drawDownDateValidity,
  isFieldApplicable,
  isFieldPercent,
  isFieldVariable,
  isGlobalFundI,
  isMSARFundType,
  saveForLater,
  setUpFeeCalculations,
  showDematError,
  updatedCommitmentAmount,
} from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import { getDirectApplicableFunds } from '../../redux-store/actions/funds';
import { getDistributorById } from '../../redux-store/actions/userManagement';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { jointHolderRelationsList } from '../../redux-store/actions';
import MFCheckbox from '../../lib/formik/Checkbox';
import { InvestorGrievances } from '../ApplicationDetails/InvestmentAndPayment';
import { showError } from '../../redux-store/actions/auth';
import { DatePicker } from '../../lib/formik/DatePicker';

type Values = Partial<ApplicationProps> & {
  fundType: string;
  saveType: string;
};

const fundInitialValues = {
  schemeId: '',
  planId: '',
  commitmentAmount: 0,
  setupFeePercentage: 0,
  managementFeeApplicable: '',
  managementFeeType: '',
  managementFeesRate: 0,
  performanceFee: 0,
  placementFee: '',
  exitLoad: 0,
  operatingExpenses: 0,
  exitLoadApplicableTill: '',
  hurdleRate: 0,
  groupInvestment: false,
  groupName: '',
  taxRate: 0,
  drawDownNumber: 0,
  contributionChequeAmount: 0,
  setupFeeAmount: null,
  setupFeeGST: null,
  totalSetupFee: null,
  setupFeeTDS: null,
  netSetupFeeLessTDS: null,
  totalAmountPayable: null,
  drawDownDate: null,
  boardMeetingDate: null,
  isoperatingExpensesApplicable: '',
  operatingExpensesType: '',
  operatingExpensesInWords: '',
  setupFeeType: '',
  setupFeeInWords: '',
};

const initialValues: Values = {
  ...fundInitialValues,
  modeOfPayment: 'cheque',
  paymentRefNo: '',
  chequeDate: '',
  paymentBankAccountNumber: '',
  paymentBankame: '',
  paymentBankAccontType: '',
  fundType: '',
  saveType: 'save and proceed',
};

export default function InvestmentPaymentDetails(): JSX.Element {
  // const [open, setOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [fundsList, setFundsList] = useState<FundProps[]>([]);
  const [selectedFund, setSelectedFund] = useState<FundProps | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Partial<ClassPlanProps> | null>(null);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [paymentDetails, setPaymentDetails] = useState(initialValues);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [jointHoldersData, setJointHoldersData] = useState<JointholderRelationMaster>();

  const {
    minCommitmentAmount = 10000000,
    maxCommitmentAmount = 20000000,
    minContributionPercentage = 10,
    currency = 'inr',
    minManagementFee = 0,
    maxManagementFee = 100,
  } = selectedPlan || {};

  useEffect(() => {
    const { plan } = application || ({} as ApplicationProps);
    const {
      minCommitmentAmount = 1,
      maxCommitmentAmount = 0,
      minContributionPercentage = 1,
      currency = 'inr',
      performanceFee = 0,
      placementFee = '',
      exitLoad = 0,
      operatingExpenses = 0,
      exitLoadApplicableTill = '',
      hurdleRate = 0,
      minManagementFee = 0,
      maxManagementFee = 100,
    } = plan || {};
    setSelectedPlan(
      plan
        ? {
            ...selectedPlan,
            minCommitmentAmount: Number(minCommitmentAmount),
            maxCommitmentAmount: Number(maxCommitmentAmount),
            minContributionPercentage: Number(minContributionPercentage),
            currency,
            performanceFee,
            placementFee,
            exitLoad,
            operatingExpenses,
            exitLoadApplicableTill,
            hurdleRate,
            minManagementFee: Number(minManagementFee),
            maxManagementFee:
              maxManagementFee === 0 || maxManagementFee ? Number(maxManagementFee) : 100,
          }
        : null
    );

    setPaymentDetails({
      ...paymentDetails,
      schemeId: application?.schemeId || '',
      planId: application?.planId || '',
      commitmentAmount: application?.commitmentAmount,
      groupInvestment: application?.groupInvestment || false,
      groupName: application?.groupName || '',
      setupFeePercentage: application?.setupFeePercentage || 0,
      managementFeeApplicable: application?.managementFeeApplicable || '',
      managementFeeType: application?.managementFeeType || '',
      managementFeesRate: application?.managementFeesRate || 0,
      performanceFee: application?.performanceFee || 0,
      placementFee: application?.placementFee || '',
      exitLoad: application?.exitLoad || 0,
      operatingExpenses: application?.operatingExpenses || 0,
      exitLoadApplicableTill: application?.exitLoadApplicableTill || '',
      hurdleRate: application?.hurdleRate || 0,
      // taxRate: application?.taxRate || null,
      // drawDownNumber: application?.drawDownNumber || null,
      contributionChequeAmount: Number(application?.contributionChequeAmount) || 0,
      modeOfPayment: application?.modeOfPayment || '',
      paymentRefNo: application?.paymentRefNo || '',
      chequeDate: application?.chequeDate || null,
      paymentBankAccountNumber: application?.paymentBankAccountNumber || '',
      paymentBankame: application?.paymentBankame || '',
      paymentBankAccontType: application?.paymentBankAccontType || '',
      setupFeeAmount: application?.setupFeeAmount,
      setupFeeGST: application?.setupFeeGST,
      totalSetupFee: application?.totalSetupFee,
      setupFeeTDS: application?.setupFeeTDS,
      netSetupFeeLessTDS: application?.netSetupFeeLessTDS,
      totalAmountPayable: application?.totalAmountPayable,
      drawDownDate: application?.drawDownDate,
      boardMeetingDate: application?.boardMeetingDate,
      fundType: application?.scheme?.fundType || '',
      isoperatingExpensesApplicable: application?.isoperatingExpensesApplicable || '',
      operatingExpensesType: application?.operatingExpensesType || '',
      operatingExpensesInWords: application?.operatingExpensesInWords || '',
      setupFeeType: application?.setupFeeType || '',
      setupFeeInWords: application?.setupFeeInWords || '',
    });
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationType,
        status,
        hasPOA,
        applicationNumber,
        applicants,
      } = application || {};
      const { saveType, fundType, ...rest } = values;
      // if (
      //   selectedFund?.fundCategory === FundCategoryMasters.domestic &&
      //   applicants?.some(
      //     (applicants) =>
      //       ![DLCLMasters.nsdl, DLCLMasters.cdsl].includes(DLCLMasters[applicants?.dlclId || ''])
      //   )
      // ) {
      //   throw 'Demat cannot be Empty or have None as option';
      // }
      await showDematError(
        applicants as Partial<Applicant>[],
        selectedFund?.fundCategory || '',
        false
      );
      const checkApplication = applicationComparison(
        {
          ...application,
          setupFeePercentage: Number(application?.setupFeePercentage),
          managementFeeType: application?.managementFeeType || null,
        },
        {
          ...application,
          ...rest,
          groupName: rest.groupInvestment ? rest.groupName : null,
          contributionChequeAmount: Number(rest.contributionChequeAmount) || 0,
          setupFeePercentage: Number(rest.setupFeePercentage),
          managementFeeType: rest?.managementFeeType || null,
          managementFeesRate: isFieldApplicable(rest?.managementFeeApplicable || '')
            ? Number(rest.managementFeesRate)
            : null,
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > 3
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep && currentStep > 7
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              ...rest,
              groupName: rest.groupInvestment ? rest.groupName : null,
              contributionChequeAmount: Number(rest.contributionChequeAmount) || 0,
              setupFeePercentage: Number(rest.setupFeePercentage),
              managementFeeType: rest?.managementFeeType || null,
              managementFeesRate: isFieldApplicable(rest?.managementFeeApplicable || '')
                ? Number(rest.managementFeesRate)
                : null,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role) &&
                applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL
                  ? 'sent_to_amc_approver'
                  : status === 'sent_to_amc_approver' &&
                    hasPOA &&
                    USER_ROLES.SUBDISTRIBUTOR === role
                  ? 'draft'
                  : status,
              currentStep: applicationType === APPLICATION_TYPE.NON_INDIVIDUAL ? 4 : 8,
              // applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              //   ? !!currentStep && currentStep > 3
              //     ? currentStep
              //     : Number(currentStep) + 1
              //   : !!currentStep && currentStep > 7
              //   ? currentStep
              //   : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('bank-details', { id, applicant1ReferenceId })
            : history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('bank-details', { id, applicant1ReferenceId })
            : history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e as string));
      console.error((e as Error).message);
    }
  };

  const { scheme } = application || {};

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = application?.distributor_id
          ? ((
              (await dispatch(
                getDistributorById(application?.distributor_id)
              )) as unknown as DistributorListType
            ).planDetails.schemes as FundProps[])
          : ((await dispatch(
              getDirectApplicableFunds({ isActive: true })
            )) as unknown as FundProps[]);
        if (!isComponentAlive) {
          return;
        }
        const updatedFundPlan =
          application?.schemeId &&
          (!application.scheme.isActive ||
            !(response.map((res) => res.id?.toString()) as unknown as string[]).includes(
              application?.schemeId?.toString()
            ))
            ? [
                ...response,
                {
                  ...application.scheme,
                  plans: [application.plan],
                } as unknown as FundProps,
              ]
            : response;
        setFundsList(updatedFundPlan);
        const updatePlans =
          application?.schemeId &&
          application?.scheme.isActive &&
          application?.planId &&
          !application.plan?.isActive
            ? ({
                ...updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)),
                plans: [
                  ...(
                    updatedFundPlan.find(
                      (fund) => Number(fund.id) === Number(scheme?.id)
                    ) as unknown as FundProps
                  ).plans,
                  application.plan,
                ],
              } as unknown as FundProps)
            : updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)) || null;
        setSelectedFund(updatePlans);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const getClassPlanOptionsOrDetails = (detail?: string) => {
    if (detail) {
      return selectedFund?.[detail] || '';
    }
    return (
      selectedFund?.plans?.map((plan) => ({ key: plan.planDescription, value: Number(plan.id) })) ||
      []
    );
  };

  useEffect(() => {
    (async function () {
      try {
        const jointHodersMdmsMasters = (await dispatch(
          jointHolderRelationsList()
        )) as unknown as JointholderRelationMaster;
        setJointHoldersData(jointHodersMdmsMasters);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <Formik
      initialValues={paymentDetails}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        const minCommitmentAmountBasedOnJointHolders =
          application && application.applicants?.length > 1 && jointHoldersData
            ? (updatedCommitmentAmount(
                Number(minCommitmentAmount),
                application.applicants,
                jointHoldersData
              ) as number)
            : minCommitmentAmount;
        try {
          validateYupSchema(
            values,
            investmentPaymentDetailsSchema(
              values.groupInvestment ? 10000000 : minCommitmentAmountBasedOnJointHolders,
              maxCommitmentAmount,
              minContributionPercentage,
              selectedPlan?.currency || '',
              minManagementFee,
              maxManagementFee === 0 || maxManagementFee ? Number(maxManagementFee) : 100
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={5}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          <Grid item xs={12}>
            <MFCheckbox
              name="groupInvestment"
              label="Group Investment"
              sx={{ letterSpacing: '1px' }}
              checked={values.groupInvestment}
            />
          </Grid>
          {values.groupInvestment && (
            <Grid item xs={12} sm={6}>
              <MFTextField name={'groupName'} label="Group Name *" placeholder="Enter Group Name" />
            </Grid>
          )}
          <Grid item xs={12}>
            <SearchableSelect
              name={'schemeId'}
              label="Fund (Scheme) *"
              items={fundsList.map((fund) => ({ value: Number(fund.id), key: fund.schemeName }))}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                const selectedFund = fundsList.find((f) => Number(f.id) === value) || null;
                setValues({
                  ...values,
                  ...fundInitialValues,
                  schemeId: value as string,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                  totalAmountPayable: 0,
                  groupInvestment: values.groupInvestment || false,
                  groupName: values.groupName || '',
                  fundType: selectedFund?.fundType || '',
                  drawDownDate: null,
                  boardMeetingDate: null,
                });
                setSelectedFund(selectedFund);
                setSelectedPlan(null);
              }}
              disabled={isFieldDisabled}
              searchFieldPlaceholder={'Search Fund (Scheme)'}
            />
          </Grid>
          {/* {!!values.schemeId && (
            <Grid item xs={12}>
              <Box
                sx={{
                  boxShadow: '0px 0px 12px 2px rgba(0, 0, 0, 0.1)',
                  borderRadius: '10px',
                  p: { xs: '20px', sm: '30px 40px' },
                  my: 2,
                }}>
                <Box
                  onClick={() => setOpen(!open)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: 600,
                      fontSize: 16,
                      color: 'primary.main',
                      letterSpacing: '0.05em',
                    }}>
                    Bank Details related to this fund (Scheme)
                  </Typography>
                  <ExpandMoreIcon sx={{ transform: open ? 'rotate(180deg)' : '' }} />
                </Box>
                {open && (
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      mt: 3,
                      '.MuiTypography-root': {
                        fontSize: 16,
                        letterSpacing: '0.05em',
                        '&.MuiTypography-body2 ': { fontWeight: 600 },
                      },
                    }}>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Name</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankName')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Account Number</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankAccountNumber')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>IFSC Code</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('ifscCode')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Branch</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankBranch')}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          )} */}
          <Grid item xs={12} sm={6}>
            <SearchableSelect
              name={'planId'}
              label="Class (Plan) *"
              items={getClassPlanOptionsOrDetails() as [{ key: string; value: string | number }]}
              disabled={!values.schemeId || isFieldDisabled}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                const selectedPlan = selectedFund?.plans
                  ?.filter((plan) => plan.isActive)
                  ?.find((p) => Number(p.id) === value);
                setSelectedPlan(selectedPlan || null);
                setValues({
                  ...values,
                  planId: value as string,
                  commitmentAmount: 0,
                  contributionChequeAmount: 0,
                  setupFeePercentage: isMSARFundType(values?.fundType || '')
                    ? 0
                    : selectedPlan?.setupFee,
                  managementFeesRate: selectedPlan?.managementFee,
                  performanceFee: selectedPlan?.performanceFee,
                  placementFee: selectedPlan?.placementFee,
                  exitLoad: selectedPlan?.exitLoad,
                  operatingExpenses: selectedPlan?.operatingExpenses,
                  exitLoadApplicableTill: selectedPlan?.exitLoadApplicableTill,
                  hurdleRate: selectedPlan?.hurdleRate,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                  totalAmountPayable: 0,
                  groupInvestment: values.groupInvestment || false,
                  groupName: values.groupName || '',
                  isoperatingExpensesApplicable: selectedPlan?.isoperatingExpensesApplicable,
                  operatingExpensesType: selectedPlan?.operatingExpensesType,
                  operatingExpensesInWords: selectedPlan?.operatingExpensesInWords,
                  setupFeeType: selectedPlan?.setupFeeType,
                  setupFeeInWords: selectedPlan?.setupFeeInWords,
                  managementFeeApplicable: selectedPlan?.managementFeeApplicable,
                  managementFeeType: selectedPlan?.managementFeeType,
                });
              }}
              searchFieldPlaceholder={'Search Class (Plan)'}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'commitmentAmount'}
              label="Commitment Amount *"
              type="number"
              placeholder="Enter Commitment Amount"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                let setupFeeFields = {};
                if (!isMSARFundType(values?.fundType || '')) {
                  const feeAmountObj = setUpFeeCalculations(
                    Number(value) || 0,
                    values.setupFeePercentage || 0
                  );
                  const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                  setupFeeFields = {
                    setupFeeAmount: feeAmountObj.feeAmount,
                    setupFeeGST: feeAmountObj.feeGst,
                    totalSetupFee: feeAmountObj.totalSetupFee,
                    netSetupFeeLessTDS: netSetupFee,
                  };
                } else {
                  setupFeeFields = {
                    setupFeeAmount: 0,
                    setupFeeGST: 0,
                    totalSetupFee: 0,
                    netSetupFeeLessTDS: 0,
                  };
                }
                setValues({
                  ...values,
                  commitmentAmount: value ? Number(value) : null,
                  // setupFeeAmount: feeAmountObj.feeAmount,
                  // setupFeeGST: feeAmountObj.feeGst,
                  // totalSetupFee: feeAmountObj.totalSetupFee,
                  // netSetupFeeLessTDS: netSetupFee,
                  ...setupFeeFields,
                  totalAmountPayable: values.contributionChequeAmount || Number(value),
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
            {values.commitmentAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.commitmentAmount, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid>
          {isFieldApplicable(values?.managementFeeApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'managementFeesRate'}
                label={`Management Fees Rate % *`}
                placeholder="Enter Management Fee Percentage"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  !isFieldVariable(
                    values?.managementFeeType || '',
                    values?.managementFeeApplicable || ''
                  ) ||
                  isFieldDisabled ||
                  !values.planId
                }
              />
            </Grid>
          )}
          {isFieldApplicable(values.isoperatingExpensesApplicable || '') &&
            (isFieldPercent(
              values.operatingExpensesType || '',
              values.isoperatingExpensesApplicable || ''
            ) ? (
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'operatingExpenses'}
                  label="Operating Expenses %"
                  placeholder="Enter Operating Expenses %"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name="operatingExpensesInWords"
                  label="Operating Expenses *"
                  placeholder="Enter Operating Expenses"
                  trimOnBlur={false}
                  disabled={true}
                  multiline
                  rows={4.5}
                />
              </Grid>
            ))}

          {isMSARFundType(values?.fundType || '') && (
            <>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'performanceFee'}
                  label="Performance Fee %"
                  placeholder="Enter Performance Fee %"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'placementFee'}
                  label="Placement fee %"
                  placeholder="Enter Placement fee %"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'exitLoad'}
                  label="Exit Load %"
                  placeholder="Enter Exit Load %"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'operatingExpenses'}
                  label="Operating Expenses %"
                  placeholder="Enter Operating Expenses %"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'exitLoadApplicableTill'}
                  label="Exit Load Applicable Till"
                  placeholder="Enter Exit Load Applicable Till"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name="hurdleRate"
                  label="Hurdle Rate %"
                  placeholder="Enter Hurdle Rate %"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
              </Grid>
            </>
          )}
          {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={'taxRate'}
              label="Tax Rate"
              placeholder="Enter Tax Rate"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={'drawDownNumber'}
              label="Drawdown Number"
              items={DrawDownMasters.map((master) => ({ key: master, value: master }))}
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'contributionChequeAmount'}
              label="Contribution Amount *"
              placeholder="Enter Contribution Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  values.commitmentAmount || 0,
                  values.setupFeePercentage || 0
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  contributionChequeAmount: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: Number(value) || values.commitmentAmount,
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
            {values.contributionChequeAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.contributionChequeAmount, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid> */}
          {!isMSARFundType(values?.fundType || '') &&
            values.setupFeeType &&
            (isFieldPercent(values.setupFeeType || '', 'yes') ? (
              <>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={'setupFeePercentage'}
                    label="Setup Fee Percentage *"
                    type="number"
                    placeholder="Enter Setup Fee Percentage"
                    onKeyDown={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                      const feeAmountObj = setUpFeeCalculations(
                        values.commitmentAmount || 0,
                        Number(value)
                      );
                      const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                      setValues({
                        ...values,
                        setupFeePercentage: value ? Number(value) : null,
                        setupFeeAmount: feeAmountObj.feeAmount,
                        setupFeeGST: feeAmountObj.feeGst,
                        totalSetupFee: feeAmountObj.totalSetupFee,
                        netSetupFeeLessTDS: netSetupFee,
                        totalAmountPayable:
                          values.contributionChequeAmount || values.commitmentAmount,
                      });
                    }}
                    trimOnBlur={false}
                    disabled={isFieldDisabled || !values.planId}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                  <MFTextField
                    name={'setupFeeAmount'}
                    label="Setup Fee Amount"
                    type="number"
                    placeholder="Enter Setup Fee Amount"
                    onKeyDown={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    trimOnBlur={false}
                    disabled={true}
                  />
                  {values.setupFeeAmount != 0 && (
                    <Typography sx={{ fontSize: 13 }}>
                      {currencyConversion(values.setupFeeAmount, selectedPlan?.currency)}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                  <MFTextField
                    name={'setupFeeGST'}
                    label="GST (GST On Setup Fee Amount)"
                    type="number"
                    placeholder="Enter GST"
                    onKeyDown={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    trimOnBlur={false}
                    disabled={true}
                  />
                  {values.setupFeeGST != 0 && (
                    <Typography sx={{ fontSize: 13 }}>
                      {currencyConversion(values.setupFeeGST, selectedPlan?.currency)}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                  <MFTextField
                    name={'totalSetupFee'}
                    label="Total Setup Fee"
                    type="number"
                    placeholder="Enter Total Setup Fee"
                    onKeyDown={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    trimOnBlur={false}
                    disabled={true}
                  />
                  {values.totalSetupFee != 0 && (
                    <Typography sx={{ fontSize: 13 }}>
                      {currencyConversion(values.totalSetupFee, selectedPlan?.currency)}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                  <MFTextField
                    name={'setupFeeTDS'}
                    label="TDS"
                    type="number"
                    placeholder="Enter TDS"
                    onKeyDown={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                      const setupFeeLessTDS = (values.totalSetupFee || 0) - Number(value);
                      setValues({
                        ...values,
                        setupFeeTDS: value ? Number(value) : null,
                        netSetupFeeLessTDS:
                          values.totalSetupFee && value ? setupFeeLessTDS : values.totalSetupFee,
                        totalAmountPayable:
                          values.contributionChequeAmount || values.commitmentAmount,
                      });
                    }}
                    trimOnBlur={false}
                    disabled={isFieldDisabled || !values.planId}
                  />
                  {values.setupFeeTDS != 0 && (
                    <Typography sx={{ fontSize: 13 }}>
                      {currencyConversion(values.setupFeeTDS, selectedPlan?.currency)}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                  <MFTextField
                    name={'netSetupFeeLessTDS'}
                    label="Setup Fee Payable"
                    type="number"
                    placeholder="Enter Setup Fee Payable"
                    onKeyDown={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    trimOnBlur={false}
                    disabled={true}
                  />
                  {values.netSetupFeeLessTDS != 0 && (
                    <Typography sx={{ fontSize: 13 }}>
                      {currencyConversion(values.netSetupFeeLessTDS, selectedPlan?.currency)}
                    </Typography>
                  )}
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name="setupFeeInWords"
                  label="Setup Fee *"
                  placeholder="Setup Fee"
                  multiline
                  rows={4.5}
                  trimOnBlur={false}
                  disabled={true}
                />
              </Grid>
            ))}
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'totalAmountPayable'}
              label="Total Amount Payable"
              type="number"
              placeholder="Enter Total Amount Payable"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.totalAmountPayable != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.totalAmountPayable, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid>
          {isMSARFundType(values?.fundType || '') && (
            <>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label={'Drawdown Date *'}
                  inputLabelStyles={{
                    transform: 'unset',
                    fontSize: 14,
                    fontWeight: 500,
                    color: 'rgba(0,0,0,0.7)',
                  }}
                  placeholder={'Enter Drawdown Date'}
                  minDate={drawDownDateStarting(application?.createdAt || '')}
                  maxDate={drawDownDateValidity(application?.createdAt || '')}
                  name={`drawDownDate`}
                />
              </Grid>
              {checkIfApplicationIsNonIndividual((application || {}) as ApplicationProps) && (
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Date of Board Meeting'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    placeholder={'Enter Date of Board Meeting'}
                    maxDate={null}
                    name={`boardMeetingDate`}
                  />
                </Grid>
              )}
            </>
          )}
          {isGlobalFundI(values.schemeId || '') && <InvestorGrievances />}
          {/* <Grid
            item
            xs={12}
            onClick={() => setPaymentOpen(!paymentOpen)}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              my: 2,
            }}>
            <Typography
              component="h3"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: 25,
                fontWeight: 500,
                color: 'secondary.main',
              }}>
              Payment Details
            </Typography>
            <ExpandMoreIcon sx={{ fontSize: 30, transform: paymentOpen ? 'rotate(180deg)' : '' }} />
          </Grid> */}
          {/* {paymentOpen && (
            <>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankame'}
                  label="Payment Bank Name"
                  placeholder="Enter Payment Bank Name"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankAccountNumber'}
                  label="Payment Bank Account Number"
                  placeholder="Enter Payment Bank Account Number"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
                <UseRadioGroup
                  name={'paymentBankAccontType'}
                  formLabel="Payment Bank Account Type"
                  defaultValue="current"
                  items={[
                    { label: 'Current', value: 'current' },
                    { label: 'Savings', value: 'savings' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <UseRadioGroup
                  name={'modeOfPayment'}
                  formLabel="Mode of Payment"
                  defaultValue="cheque"
                  items={[
                    { label: 'Cheque', value: 'cheque' },
                    { label: 'RTGS', value: 'rtgs' },
                    { label: 'Online Transfer', value: 'online-transfer' },
                    { label: 'Others', value: 'others' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              {values.modeOfPayment === 'cheque' && (
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Payment/Cheque Date'}
                    placeholder={'Enter Cheque Date'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    name="chequeDate"
                    disabled={isFieldDisabled}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentRefNo'}
                  label="Payment Ref No"
                  placeholder="Enter Payment Ref No"
                  disabled={isFieldDisabled}
                />
              </Grid>
            </>
          )} */}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
